import { useEffect, useMemo, useRef, useState } from 'react';

import Modal from 'react-modal';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';

import Button from '@/components/Button';
import UploadLogo from './UploadLogo';
import RemoveLogo from './RemoveLogo';

import { handleImageExtension, handleSelectedImage } from '@/lib/helper';

import { InputTypes } from '@/src/enums/enums';
import { SettingsDetailsTypes } from '../../interface/settings.interface';

import {
  ALLOWED_IMAGE_FILE_TYPES,
  ALLOWED_IMAGE_FILE_TYPES_ARRAY,
} from '@/lib/constants';

//import { GetSettingsPageDetailsService } from '../../services/settings.service';

import OrganizationDefaultLogo from '@/src/assets/imagesComponents/OrganizationDefaultLogo';
import { useAppSelector } from '@/hooks/useRedux';

import settingSlice from '../../store/slice/settings.slice';
import { useDispatch } from 'react-redux';
import useLoading from '@/hooks/useLoading';

const LogoHeader: React.FC<{ organization_id: any }> = ({ organization_id }): JSX.Element => {
  const allowedFileTypes: string = ALLOWED_IMAGE_FILE_TYPES;
  const maxImageSize: number = 1000000;

  const imageSelect = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<{
    uploadModal: boolean;
    removeLogo: boolean;
  }>({
    uploadModal: false,
    removeLogo: false,
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string>('');
  const [imageExtension, setImageExtension] = useState<string>('');

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<{
    isImageLoading: boolean;
    isDetailsLoading: boolean;
  }>({
    isImageLoading: false,
    isDetailsLoading: false,
  });

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0] as File;
      if (
        file &&
        typeof handleSelectedImage(
          file.size,
          maxImageSize,
          file.type,
          ALLOWED_IMAGE_FILE_TYPES_ARRAY
        ) === InputTypes.NUMBER
      ) {
        // To set preview image
        setImageExtension(handleImageExtension(file.type));
        setSelectedFile(file);
        const objectUrl = window.URL.createObjectURL(file);
        setPreviewImage(objectUrl as string);
      } else {
        toast.error(
          handleSelectedImage(
            file.size,
            maxImageSize,
            file.type,
            ALLOWED_IMAGE_FILE_TYPES_ARRAY
          ),
          {
            theme: 'colored',
          }
        );
      }
    }
  };

  const settingsDetails = useAppSelector(
    state => state.settings.organizationDetails
  );



  const handleSettingsDetail = async () => {
    dispatch(settingSlice.actions.getOrganizationDetails(organization_id));
  };

  useMemo(() => {
    if (previewImage) setIsModalOpen(prev => ({ ...prev, uploadModal: true }));
  }, [previewImage]);

  return (
    <div className="px-6 py-5 flex flex-col shadow-pageShadow bg-white mb-4 rounded-lg ">
      <span className="text-sm font-medium text-greyBlackColor pb-2">Logo</span>
      <div className="border-t border-sideNavButtonBg flex justify-between pt-3">
        <div className="flex items-center">
          <div className="w-[60px] h-[60px] bg-[#F9F9F9] rounded-full flex justify-center items-center">
            {!isLoading.isImageLoading ? (
              settingsDetails?.organization_settings?.logo ? (
                <img
                  src={settingsDetails?.organization_settings?.logo}
                  className="rounded-full h-full"
                />
              ) : (
                <OrganizationDefaultLogo
                  onClick={() => imageSelect.current?.click()}
                />
              )
            ) : (
              <div>
                <Skeleton
                  className="z-10"
                  count={1}
                  circle
                  width={60}
                  height={60}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col pl-2">
            <span className="text-sm font-bold text-blackLightColor capitalize">
              {settingsDetails?.name ? settingsDetails?.name : '--'}
            </span>
            <span className="text-xs font-medium text-greyDarkBlackColor pt-1 lowercase">
              {settingsDetails?.admin_user?.email
                ? settingsDetails?.admin_user?.email
                : '--'}
            </span>
          </div>
        </div>
        <div className="flex items-end ">
          <label
            htmlFor="files"
            className="w-32 cursor-pointer border flex items-center border-greenLightColor text-greenLightColor rounded h-9 text-sm font-medium px-4 hover:bg-greenLightColor hover:text-white"
          >
            {settingsDetails?.organization_settings?.logo ? 'Change' : 'Upload'}{' '}
            Logo
          </label>
          <input
            type={InputTypes.FILE}
            id="files"
            className="hidden"
            ref={imageSelect}
            accept={allowedFileTypes}
            onChange={handleImageChange}
          />
          {settingsDetails?.organization_settings?.logo && (
            <Button
              text={'Remove Logo'}
              className={
                'w-32 border-none text-redErrorColor h-9 text-sm font-medium '
              }
              onClick={() =>
                setIsModalOpen(prev => ({ ...prev, removeLogo: true }))
              }
            />
          )}
        </div>
      </div>
      {isModalOpen.uploadModal && (
        <Modal
          isOpen={isModalOpen.uploadModal}
          ariaHideApp={false}
          className="mx-auto rounded-lg shadow-modal"
          style={{
            overlay: {
              display: 'flex',
              position: 'fixed',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0.61)',
            },
          }}
          onAfterClose={() => {
            if (imageSelect.current) {
              imageSelect.current.value = '';
            }
          }}
        >
          <UploadLogo
            id={organization_id}
            previewImage={selectedFile ? previewImage : ''}
            imageExtension={imageExtension}
            isLoading={isLoading}
            setIsModalOpen={setIsModalOpen}
            setIsLoading={setIsLoading}
            handleSettingsDetail={handleSettingsDetail}
          />
        </Modal>
      )}

      <Modal
        isOpen={isModalOpen.removeLogo}
        ariaHideApp={false}
        className="mx-auto rounded-lg shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <RemoveLogo
          id={organization_id}
          setIsModalOpen={setIsModalOpen}
          handleSettingsDetail={handleSettingsDetail}
        />
      </Modal>
    </div>
  );
};

export default LogoHeader;