import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { AxiosError } from 'axios';

import ReactCrop, { type Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';

import Button from '@/components/Button';

import {
  GetPresignedURLService,
  SendPresignedURLService,
  UploadImageToS3Service,
} from '../../services/settings.service';

import { InputTypes } from '@/src/enums/enums';

import { LOGO_UPDATED_SUCCESS_MSG } from '@/lib/constants';

const UploadLogo = ({
  previewImage,
  imageExtension,
  isLoading,
  setIsModalOpen,
  setIsLoading,
  handleSettingsDetail,
  id
}: {
  id: string,
  previewImage: string;
  imageExtension: string;
  isLoading: {
    isImageLoading: boolean;
    isDetailsLoading: boolean;
  };
  setIsModalOpen: Dispatch<
    SetStateAction<{
      uploadModal: boolean;
      removeLogo: boolean;
    }>
  >;
  setIsLoading: Dispatch<
    SetStateAction<{
      isImageLoading: boolean;
      isDetailsLoading: boolean;
    }>
  >;
  handleSettingsDetail: () => Promise<void>;
}): JSX.Element => {
  const cropperImageRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [croppedImage, setCroppedImage] = useState<Blob | null>(null);

  const imageRef: any = useRef();

  // To handle generating the cropped image from the raw image selected via file manager
  const handleOnCropComplete = (croppedArea: Crop): void => {
    if (previewImage && croppedArea.width && croppedArea.height) {
      const image = new Image();
      image.src = previewImage;
      const scaleX = image.naturalWidth / (cropperImageRef.current?.width ?? 1);
      const scaleY =
        image.naturalHeight / (cropperImageRef.current?.height ?? 1);
      const canvas = document.createElement('canvas');
      canvas.width = croppedArea.width;
      canvas.height = croppedArea.height;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(
          image,
          croppedArea.x * scaleX,
          croppedArea.y * scaleY,
          croppedArea.width * scaleX,
          croppedArea.height * scaleY,
          0,
          0,
          croppedArea.width,
          croppedArea.height
        );
        canvas.toBlob(blob => {
          setCroppedImage(blob);
        });
      }
    }
  };

  // Function to fetch s3 presigned url
  const handleFetchPresignedUrl = async () => {
    if (imageExtension) {
      try {
        const body = {
          content_type: 'image/*',
          file_extension: imageExtension,
        };
        const response = await GetPresignedURLService(body, id);
        return response;
      } catch (err) {
        toast.error((err as AxiosError).message, { theme: 'colored' });
      }
    }
  };

  // Function to receive the presigned s3 url and call it to upload the cropped image as formData()
  const handleUpload = async (): Promise<void> => {
    try {
      if (croppedImage) {
        setIsLoading(prev => ({ ...prev, isImageLoading: true }));
        const presignedUrlResponse = await handleFetchPresignedUrl();
        const formData = new FormData();
        formData.append(InputTypes.FILE, croppedImage);
        if (presignedUrlResponse?.uploadURL) {
          const s3response = await UploadImageToS3Service(
            presignedUrlResponse.uploadURL as string,
            formData.get(InputTypes.FILE)
          );
          if (s3response) {
            const body = {
              logo: presignedUrlResponse.logo_url,
            };
            const response = await SendPresignedURLService(body, id);
            if (response) {
              toast.success(LOGO_UPDATED_SUCCESS_MSG, { theme: 'colored' });
              setIsModalOpen(prev => ({ ...prev, uploadModal: false }));
              await handleSettingsDetail();
            }
          }
        }
      }
    } catch (err) {
      toast.error(err as string, { theme: 'colored' });
    }
    setIsLoading(prev => ({ ...prev, isImageLoading: false }));
  };

  return (
    <div className=" bg-white rounded-lg shadow-xl l w-[399px] font-Inter p-6">
      <div className="flex flex-col">
        <h3
          className="text-xl font-semibold leading-6 text-blackDarkColor"
          id="modal-title"
        >
          Upload Logo
        </h3>
      </div>

      <div className="rounded mt-5">
        <ReactCrop
          crop={crop}
          onChange={newCrop => setCrop(newCrop)}
          onComplete={handleOnCropComplete}
          keepSelection
        >
          <img
            className="rounded"
            src={previewImage}
            ref={cropperImageRef}
            onLoad={image => {
              setCrop({ unit: '%', width: 50, height: 50, x: 1, y: 1 });
              imageRef.current = image;
            }}
          />
        </ReactCrop>
      </div>

      <div className="flex w-full mt-[30px]">
        <Button
          text="Cancel"
          type="button"
          onClick={() => {
            setIsModalOpen(prev => ({ ...prev, uploadModal: false }));
          }}
          className="inline-flex w-1/2 mr-3 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
        />

        <Button
          text="Upload"
          type="button"
          isLoading={isLoading.isImageLoading}
          disabled={isLoading.isImageLoading}
          onClick={handleUpload}
          className={`inline-flex w-1/2 ml-3 h-46 border border-buttonLightGreen ${!isLoading && 'hover:bg-white hover:text-buttonLightGreen'
            }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
        />
      </div>
    </div>
  );
};

export default UploadLogo;
