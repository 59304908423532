import { Dispatch, SetStateAction, useState } from 'react';

import { toast } from 'react-toastify';

import { AxiosError } from 'axios';

import Button from '@/components/Button/Button';

import { RemoveLogoService } from '../../services/settings.service';

import { REMOVE_LOGO_SUCCESS } from '@/lib/constants';

import CloseIcon from '../../../../assets/images/svg/close_icon.svg';

const RemoveUser = ({
  setIsModalOpen,
  handleSettingsDetail,
  id
}: {
  id: string,
  setIsModalOpen: Dispatch<
    SetStateAction<{
      uploadModal: boolean;
      removeLogo: boolean;
    }>
  >;
  handleSettingsDetail: () => Promise<void>;
}): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseRemoveModal = (): void => {
    setIsModalOpen(prev => ({ ...prev, removeLogo: false }));
  };

  const handleRemoveLogo = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await RemoveLogoService(id);
      toast.success(REMOVE_LOGO_SUCCESS, { theme: 'colored' });
      handleCloseRemoveModal();
      await handleSettingsDetail();
    } catch (err) {
      toast.error((err as AxiosError).message, { theme: 'colored' });
    }
    setIsLoading(false);
  };

  return (
    <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all sm:my-8 w-[348px] font-Inter">
      <div className=" p-6 ">
        <div className="mt-3 text-left">
          <img
            src={CloseIcon}
            alt="*"
            className="cursor-pointer absolute right-5 top-8"
            onClick={handleCloseRemoveModal}
          />
          <h3
            className="text-xl font-semibold leading-6 text-blackDarkColor"
            id="modal-title"
          >
            Remove Logo
          </h3>
          <div className="mt-4">
            <p className="text-sm font-medium text-greyBlackLightColor leading-normal">
              Are you sure you want to remove the organization logo?
            </p>
          </div>
        </div>
      </div>
      <div className=" pt-3 pb-6 flex px-6 w-full">
        <button
          type="button"
          onClick={handleCloseRemoveModal}
          className="inline-flex justify-center items-center rounded h-9 border border-greyLightColor text-sm font-medium text-interBlack w-1/2"
        >
          Cancel
        </button>
        <Button
          type="button"
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleRemoveLogo}
          text="Remove Logo"
          className="inline-flex justify-center items-center h-9 rounded bg-redButtonColor text-sm font-medium text-white ml-3 w-1/2 hover:opacity-50"
        />
      </div>
    </div>
  );
};

export default RemoveUser;
