import { useRef } from 'react';
import { useStore } from '@/hooks/useRedux';
import {
  createSearchParams,
  NavLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import InfiniteScroll from 'react-infinite-scroller';

import organizationSlice from '../../store/slice/organization.slice';

import SettingIcon from '@/src/assets/imagesComponents/SettingsIcon';
import NoHistoryIcon from '@/src/assets/imagesComponents/NoHistoryIcon';
import { ORGANIZATIONS_DETAILS, SETTINGS } from '@/src/routes/routesConstants';

const OrganizationHistory = ({
  organization_id,
}: {
  organization_id: string;
}): JSX.Element => {
  const { state, dispatch } = useStore();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    history: orgHistory,
    historyLoading,
    historyLastPage,
    historyPage,
  } = state.organizations;

  const scrollParentRef = useRef<HTMLDivElement>(null);

  const organization_name = params.get('organization_name');

  const navigateToOrganizationSettings = (
    e: React.MouseEvent<HTMLAnchorElement>,
    organization_id: string
  ): void => {
    e.preventDefault();
    console.log(organization_id);
    navigate({
      pathname: `${ORGANIZATIONS_DETAILS}/${SETTINGS}`,
      search: createSearchParams({
        id: organization_id,
        organization_name: organization_name!,
      }).toString(),
    });
  };

  return (
    <div className="flex flex-col">
      <div className="shadow-pageShadow bg-white rounded-lg ml-4">
        <div className="flex items-center justify-between pt-4 pb-3 px-6 border-b border-sideNavButtonBg">
          <span className="text-lg text-greyBlackColor font-semibold">
            History
          </span>
        </div>

        <div className="overflow-y-auto h-84 px-6 pt-3" ref={scrollParentRef}>
          <div>
            <ul className="list">
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {
                  dispatch(
                    organizationSlice.actions.setHistoryPage({
                      historyPage: state.organizations.historyPage + 1,
                    })
                  );
                }}
                hasMore={
                  historyPage < historyLastPage && !historyLoading
                    ? true
                    : false
                }
                useWindow={false}
                getScrollParent={() => scrollParentRef.current!}
              >
                {orgHistory.map((item, index) => (
                  <>
                    <li
                      key={`${item.message + index}`}
                      className="flex items-center relative py-2"
                    >
                      <div
                        className={`dot w-[6px] h-[6px] rounded-full  ${
                          index === 0 ? 'bg-[#98A2B3]' : 'bg-greyBlackColor'
                        }`}
                      />
                      <span
                        className={`text-sm font-medium pl-3 ${
                          index === 0 ? 'text-[#98A2B3]' : 'text-greyBlackColor'
                        }`}
                      >
                        {item.message}
                      </span>
                    </li>
                  </>
                ))}
              </InfiniteScroll>
            </ul>
          </div>
          {historyLoading && (
            <Skeleton count={orgHistory.length === 0 ? 8 : 3} />
          )}

          {/* if there is no history */}
          {!historyLoading && orgHistory.length === 0 && (
            <div className="flex flex-col justify-center items-center">
              <NoHistoryIcon />

              <span className="text-lg font-medium text-interBlack py-1">
                No History
              </span>
            </div>
          )}
        </div>
      </div>

      <NavLink
        to={''}
        onClick={e => {
          if (organization_id)
            navigateToOrganizationSettings(e, organization_id);
        }}
      >
        <div
          className={`flex justify-center py-3 mt-10 rounded bg-white ml-3 cursor-pointer
             text-greenLightColor usersListBtn hover:text-white hover:bg-greenLightColor
              border border-greenLightColor orgSettingsBtn`}
        >
          <div className="mr-2">
            <SettingIcon />
          </div>
          Organization Settings
        </div>
      </NavLink>
    </div>
  );
};

export default OrganizationHistory;
