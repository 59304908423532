import type { VoidGenerator } from "@/types/index";
import { call, put, takeEvery } from "redux-saga/effects";
import type { PutEffect, CallEffect } from "redux-saga/effects";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { LoginBody } from "@/types/auth";
import loaderSlice from "../../../../store/slices/loader.slice";


import settingSlice from "../slice/settings.slice";
import { GetHECMProductsBody, LosConfigurations, LosConfigurationsRequestBody, ProductListEntity, SettingsDetailsTypes, UpdateOrganizationDetailsBody, UpdateOrganizationPrivacyPolicyBody } from "../../interface/settings.interface";
import { GetHecmProducts, GetLosConfigurations, GetSettingsPageDetailsService, SaveOrUpdateLosConfigurations, UpdateOrganizationDetails, UpdateOrganizationPrivacyPolicy } from "../../services/settings.service";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

function* settingsSaga(action: PayloadAction<LoginBody>): VoidGenerator<PutEffect | CallEffect, Promise<boolean>> {
  try {
    yield put(loaderSlice.actions.startLoadingAction(""));
  } catch (e) {
  } finally {
    yield put(loaderSlice.actions.stopLoadingAction(""));
  }
}


function* getOrganizationDetails(action: PayloadAction<string>) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.getOrganizationDetails.type
      )
    );
    const response: SettingsDetailsTypes = yield call(
      GetSettingsPageDetailsService, action.payload
    );
    if (response)
      yield put(settingSlice.actions.setOrganizationDetails(response));
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      loaderSlice.actions.stopLoadingAction(
        settingSlice.actions.getOrganizationDetails.type
      )
    );
  }
}




function* updateOrganizationDetails(
  action: PayloadAction<UpdateOrganizationDetailsBody>
) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.updateOrganizationDetails.type
      )
    );
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.getOrganizationDetails.type
      )
    );
    yield call(UpdateOrganizationDetails, action.payload);
    yield put(settingSlice.actions.getOrganizationDetails(action.payload.organization_id));
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      loaderSlice.actions.stopLoadingAction(
        settingSlice.actions.updateOrganizationDetails.type
      )
    );
  }
}


function* updateOrganizationPrivacyPolicy(
  action: PayloadAction<UpdateOrganizationPrivacyPolicyBody>
) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.updateOrganizationPrivacyPolicy.type
      )
    );
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.getOrganizationDetails.type
      )
    );
    yield call(UpdateOrganizationPrivacyPolicy, action.payload);
    yield put(settingSlice.actions.getOrganizationDetails(action.payload.organization_id));
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      loaderSlice.actions.stopLoadingAction(
        settingSlice.actions.updateOrganizationPrivacyPolicy.type
      )
    );
  }
}


function* getLosConfigurations(action: PayloadAction<string>) {
  try {

    yield put(settingSlice.actions.setIsLosConfigurationsLoading(true));
    const response: LosConfigurations = yield call(GetLosConfigurations, action.payload);
    if (response) {
      yield put(settingSlice.actions.setLosConfigurations(response));
      if (!response.api_key && !response.los_company_id) {
        yield put(settingSlice.actions.setIsLosSettingsEdit(true));
      }
    }
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(settingSlice.actions.setIsLosConfigurationsLoading(false));
  }
}

function* getHecmProducts(action: PayloadAction<GetHECMProductsBody>) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.getHecmProducts.type
      )
    );
    const response: ProductListEntity[] = yield call(
      GetHecmProducts,
      action.payload
    );
    if (response) yield put(settingSlice.actions.setHecmProducts(response));
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      loaderSlice.actions.stopLoadingAction(
        settingSlice.actions.getHecmProducts.type
      )
    );
  }
}

function* saveOrUpdateLosConfigurations(
  action: PayloadAction<LosConfigurationsRequestBody>
) {
  try {
    yield put(
      loaderSlice.actions.startLoadingAction(
        settingSlice.actions.saveOrUpdateLosConfigurations.type
      )
    );
    const ResponseMessage: string = yield call(
      SaveOrUpdateLosConfigurations,
      action.payload
    );

    toast.success(ResponseMessage, {
      theme: 'colored',
    });

    yield put(settingSlice.actions.setIsLosSettingsEdit(false));
    console.log(action.payload.los_settings.organization_id)
    yield put(settingSlice.actions.getLosConfigurations(action?.payload?.los_settings?.organization_id));
  } catch (err) {
    toast.error((err as AxiosError).message, { theme: 'colored' });
  } finally {
    yield put(
      loaderSlice.actions.stopLoadingAction(
        settingSlice.actions.saveOrUpdateLosConfigurations.type
      )
    );
  }
}


// function* getLosConfigurations(action: PayloadAction<string>) {
//   try {

//     yield put(settingSlice.actions.setIsLosConfigurationsLoading(true));
//     const response: LosConfigurations = yield call(GetLosConfigurations, action.payload);
//     if (response) {
//       yield put(settingSlice.actions.setLosConfigurations(response));
//       if (!response.api_key && !response.los_company_id) {
//         yield put(settingSlice.actions.setIsLosSettingsEdit(true));
//       }
//     }
//   } catch (err) {
//     toast.error((err as AxiosError).message, { theme: 'colored' });
//   } finally {
//     yield put(settingSlice.actions.setIsLosConfigurationsLoading(false));
//   }
// }

// function* getHecmProducts(action: PayloadAction<GetHECMProductsBody>) {
//   try {
//     yield put(
//       loaderSlice.actions.startLoadingAction(
//         settingSlice.actions.getHecmProducts.type
//       )
//     );
//     const response: ProductListEntity[] = yield call(
//       GetHecmProducts,
//       action.payload
//     );
//     if (response) yield put(settingSlice.actions.setHecmProducts(response));
//   } catch (err) {
//     toast.error((err as AxiosError).message, { theme: 'colored' });
//   } finally {
//     yield put(
//       loaderSlice.actions.stopLoadingAction(
//         settingSlice.actions.getHecmProducts.type
//       )
//     );
//   }
// }

// function* saveOrUpdateLosConfigurations(
//   action: PayloadAction<LosConfigurationsRequestBody>
// ) {
//   try {
//     yield put(
//       loaderSlice.actions.startLoadingAction(
//         settingSlice.actions.saveOrUpdateLosConfigurations.type
//       )
//     );
//     const ResponseMessage: string = yield call(
//       SaveOrUpdateLosConfigurations,
//       action.payload
//     );

//     toast.success(ResponseMessage, {
//       theme: 'colored',
//     });

//     yield put(settingSlice.actions.setIsLosSettingsEdit(false));
//     yield put(settingSlice.actions.getLosConfigurations(action.payload));
//   } catch (err) {
//     toast.error((err as AxiosError).message, { theme: 'colored' });
//   } finally {
//     yield put(
//       loaderSlice.actions.stopLoadingAction(
//         settingSlice.actions.saveOrUpdateLosConfigurations.type
//       )
//     );
//   }
// }


export default function* settingSaga() {
  yield takeEvery("settingsSaga", settingsSaga);

  yield takeEvery(
    settingSlice.actions.getOrganizationDetails.type,
    getOrganizationDetails
  );

  yield takeEvery(
    settingSlice.actions.updateOrganizationDetails.type,
    updateOrganizationDetails
  );

  yield takeEvery(
    settingSlice.actions.updateOrganizationPrivacyPolicy.type,
    updateOrganizationPrivacyPolicy
  );

  yield takeEvery(
    settingSlice.actions.getLosConfigurations.type,
    getLosConfigurations
  );

  yield takeEvery(settingSlice.actions.getHecmProducts.type, getHecmProducts);
  yield takeEvery(
    settingSlice.actions.saveOrUpdateLosConfigurations.type,
    saveOrUpdateLosConfigurations
  );
  // yield takeEvery(
  //   settingSlice.actions.getLosConfigurations.type,
  //   getLosConfigurations
  // );

  // yield takeEvery(settingSlice.actions.getHecmProducts.type, getHecmProducts);
  // yield takeEvery(
  //   settingSlice.actions.saveOrUpdateLosConfigurations.type,
  //   saveOrUpdateLosConfigurations
  // );
}
