import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { InitialState } from '@/store/types/auth.type';
import type { LoginBody } from '@/types/auth';
import {
  AuthMeResponseTypes,
  ForgotPasswordPayload,
} from '../../interface/auth.interface';
import { AUTH_ME_DATA } from '@/lib/constants';
import { NavigateFunction } from 'react-router';

const initialState: InitialState = {
  isAuthenticated: false,
  rememberMe: false,
  authMeData: AUTH_ME_DATA,
  isAuthMeLoading: false,
  userId: null
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const login = (state: InitialState, _action: PayloadAction<LoginBody>) => state;

const setIsAuthenticated = (
  state: InitialState,
  action: PayloadAction<InitialState['isAuthenticated']>
) => ({
  ...state,
  isAuthenticated: action.payload,
});

// Slice functions to trigger AuthMeSaga and set it's response in the state autMeData
const getAuthMe = (
  state: InitialState,
  action: PayloadAction<{ rememberMe: boolean; navigate?: NavigateFunction }>
) => ({
  ...state,
  rememberMe: action.payload.rememberMe,
});
const setAuthMe = (
  state: InitialState,
  action: PayloadAction<AuthMeResponseTypes>
) => ({
  ...state,
  authMeData: action.payload,
});

const setIsAuthMeLoading = (
  state: InitialState,
  action: PayloadAction<boolean>
) => ({
  ...state,
  isAuthMeLoading: action.payload,
});

const forgotPassword = (
  state: InitialState,
  action: PayloadAction<ForgotPasswordPayload>
) => ({
  ...state,
});

const setUserId = (
  state: InitialState,
  action: PayloadAction<string | null>
) => ({
  ...state,
  userId: action.payload,
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login,
    setIsAuthenticated,
    getAuthMe,
    setAuthMe,
    setIsAuthMeLoading,
    forgotPassword,
    setUserId
  },
});

export default authSlice;
