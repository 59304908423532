import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '@/components/Button';
import CloseIcon from '@/src/assets/images/svg/close_icon.svg';
import { useDispatch } from 'react-redux';
import settingSlice from '../../store/slice/settings.slice';
import useLoading from '@/hooks/useLoading';
import { useAppSelector } from '@/hooks/useRedux';
import { organizationDetailsPrivacyPolicyValidationSchema } from '@/lib/validation';

interface Props {
  handleClose: () => void;
  organization_id: string
}
interface FormValues {
  privacyPolicy: string;
}

const EditPrivacy: React.FC<Props> = ({ handleClose, organization_id }) => {
  const isLoading = useLoading(
    settingSlice.actions.updateOrganizationPrivacyPolicy.type
  );
  const settingsDetails = useAppSelector(
    state => state.settings.organizationDetails
  );
  const dispatch = useDispatch();

  // Define initial values and validation schema
  const initialValues: FormValues = {
    privacyPolicy: '', // Initialize with an empty string
  };

  const onSubmit = (values: FormValues) => {
    dispatch(
      settingSlice.actions.updateOrganizationPrivacyPolicy({
        pdf_footer_policy: values.privacyPolicy,
        organization_id
      })
    );
    handleClose();
  };

  // Formik form setup
  const formik = useFormik({
    initialValues,
    validationSchema: organizationDetailsPrivacyPolicyValidationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (settingsDetails)
      formik.setFieldValue(
        'privacyPolicy',
        settingsDetails.organization_settings.pdf_footer_policy
      );
  }, [settingsDetails]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="relative transform overflow-hidden bg-white rounded-lg shadow-xl transition-all w-[531px] font-Inter">
        <div className="absolute right-6 top-7" onClick={handleClose}>
          <img src={CloseIcon} alt="" className="cursor-pointer" />
        </div>
        <div className="p-6">
          <div className="text-left">
            <h3
              className="text-xl font-semibold leading-6 text-blackDarkColor"
              id="modal-title"
            >
              Edit Privacy Policy
            </h3>
            <div className="mt-2">
              <p className="text-xs font-medium text-greyBlackLightColor leading-normal">
                Please add/edit the privacy policy of the organization
              </p>
            </div>
          </div>

          <div className="flex flex-col mt-6">
            <div className="w-full flex flex-col">
              <span className="text-xs text-greyLightDarkColor pb-2 font-medium">
                Privacy policy
              </span>
              <textarea
                className={`w-full h-[240px] resize-none py-2 px-3 border border-greyLightColor rounded focus:outline-none ${formik.touched.privacyPolicy && formik.errors.privacyPolicy
                    ? 'border-red-600'
                    : ''
                  }`}
                id="privacyPolicy"
                name="privacyPolicy"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.privacyPolicy}
              />
              {formik.touched.privacyPolicy && formik.errors.privacyPolicy && (
                <div className="text-red-600 text-xs mt-2">
                  {formik.errors.privacyPolicy}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="pb-6 flex px-6 w-full mt-4">
          <Button
            text="Cancel"
            type="button"
            onClick={handleClose}
            className="inline-flex w-1/2 mr-3 h-46 border border-greyLightColor hover:border-redErrorColor hover:text-redErrorColor justify-center items-center text-interBlack text-sm rounded"
          />

          <Button
            text="Save"
            type="submit"
            isLoading={isLoading}
            className={`inline-flex w-1/2 ml-3 h-46 border border-buttonLightGreen ${!isLoading && 'hover:bg-white hover:text-buttonLightGreen'
              }   bg-buttonLightGreen justify-center items-center text-white text-sm rounded `}
          />
        </div>
      </div>
    </form>
  );
};

export default EditPrivacy;
