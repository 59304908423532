import { useEffect } from 'react';
import { useParams } from 'react-router';
import LogoHeader from './Components/LogoHeader';
import OrganizationView from './Components/OrganizationView';
import ReportSetting from './Components/ReportSetting';
import Modal from 'react-modal';
import EditPrivacy from './Components/EditPrivacy';
import { useAppSelector } from '@/hooks/useRedux';
import useLoading from '@/hooks/useLoading';
import settingSlice from '../store/slice/settings.slice';
import zIndex from '@mui/material/styles/zIndex';
import OrganizationDetailsLoader from './Components/OrganizationDetailsLoader';
import { useDispatch } from 'react-redux';
import PrivacyPolicy from './Components/PrivacyPolicy';
import { RootState } from '../../../store';
import authSlice from '../../Authentication/store/slice/auth.slice';
import { useSearchParams } from 'react-router-dom';

const Settings = () => {
  const dispatch = useDispatch();
  //const { id } = useParams<{ id: string }>();
  const [params] = useSearchParams();
  const id = params.get('id');

  const settingsDetails = useAppSelector(
    state => state.settings.organizationDetails
  );

  const isDetailsLoading = useLoading(
    settingSlice.actions.getOrganizationDetails.type
  );

  const { userId } = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    dispatch(settingSlice.actions.getOrganizationDetails(id));
  }, [id]);

  useEffect(() => {
    () => {
      if (userId) {
        dispatch(authSlice.actions.setUserId(null));
      }
    };
  }, [userId]);

  return (
    <div className="flex font-inter h-full overflow-y-auto">
      <div className="w-3/5 mr-2 flex flex-col">
        {isDetailsLoading ? (
          <OrganizationDetailsLoader />
        ) : (
          <>
            <LogoHeader organization_id={id} />

            <OrganizationView organization_id={id} />

            <div className="px-6 py-5 flex flex-col shadow-pageShadow bg-white mb-4 rounded-lg ">
              <div className="flex justify-between items-center  pb-2">
                <span className="text-sm font-medium text-greyBlackColor">
                  Report ID
                </span>
              </div>

              <div className="border-t border-sideNavButtonBg flex pt-3">
                <div className="flex w-full">
                  <span className="text-xs font-medium text-greyDarkBlackColor w-1/2">
                    Report ID Format
                  </span>
                  <span className="text-sm font-medium text-blackLightColor  capitalize w-1/2">
                    {settingsDetails?.organization_settings.report_format}
                  </span>
                </div>
              </div>
            </div>
            <PrivacyPolicy organization_id={id} />
          </>
        )}
      </div>

      {/* <div className="w-2/5 ml-2 shadow-pageShadow bg-white flex flex-col rounded-lg">
        <ReportSetting />
      </div> */}
    </div>
  );
};

export default Settings;
