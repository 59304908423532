import { Dispatch, Fragment, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AnyAction } from 'redux';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';
import { TopNavData } from '@/modules/Settings/interface/settings.interface';
import { auth } from '@/lib/firebase';
import Modal from 'react-modal';
import authSlice from '@/modules/Authentication/store/slice/auth.slice';
import { routesArray, ORGANIZATIONS } from 'routes/routesConstants';
import { AUTH_ME_DATA, ORGANIZATIONS_DATA } from '@/lib/constants';
import OrganizationsIcon from '@/src/assets/imagesComponents/OrganizationsIcon';
import EquityReleaseLogo from '../../equity_release_logo.svg';
import AdminAvatar from '../../assets/images/svg/admin_avatar.svg';
import ChangePasswordIcon from '../../assets/images/svg/changePassword.svg';
import LogoutIcon from '../../assets/images/svg/logout_icon.svg';
import ChangePassword from './ChangePassword';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
const SideNav = (): JSX.Element => {
  const pageUrl: string = window.location.pathname;
  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const [isChangePasswordOpen, setIsChangePasswordOpen] =
    useState<boolean>(false);
  // Handle logout functionality whilst also clearing AuthMeData state
  const handleLogout = async (): Promise<void> => {
    await auth.signOut();
    dispatch(authSlice.actions.setAuthMe(AUTH_ME_DATA));
    localStorage.clear();
    sessionStorage.clear();
  };

  // To handle header texts and button texts on page load
  const handleNavigationOnRender = (): void => {
    routesArray.forEach(item => {
      if (pageUrl.includes(item)) handleNavigation(item);
    });
  };

  useEffect((): void => {
    handleNavigationOnRender();
  }, []);

  // To handle header texts and button texts on page load by clicking side nav buttons
  const handleNavigation = (page: string): void => {
    switch (page) {
      case ORGANIZATIONS:
        dispatch(
          settingSlice.actions.setTopNavData({
            heading: ORGANIZATIONS_DATA.heading,
            buttonText: ORGANIZATIONS_DATA.buttonText,
          } as TopNavData)
        );
        break;

      default:
        break;
    }
  };

  function closeModal() {
    setIsChangePasswordOpen(false);
  }

  return (
    <Fragment>
      <div className="flex justify-between flex-col py-10 w-full h-screen bg-white border border-r-greyLightColor">
        <div>
          <div className="flex mx-9 mb-12 items-center">
            <div className="">
              <img className="w-9 h-9" src={EquityReleaseLogo} alt="*" />
            </div>
            <div>
              <a href="#" className="flex items-center py-4 px-4">
                <span className="font-semibold font-Inter text-base text-interBlack">
                  HECM Tool
                </span>
              </a>
            </div>
          </div>

          <NavLink
            to={ORGANIZATIONS}
            onClick={() => handleNavigation(ORGANIZATIONS)}
          >
            <div className="flex mx-5 mt-2 mb-1 items-center  bg-sideNavButtonBg rounded h-42 menuItems">
              <div className="ml-4">
                <OrganizationsIcon />
              </div>
              <div>
                <a href="#" className="flex items-center py-3 px-4">
                  <span className="font-semibold font-Inter text-sm text-interBlack">
                    Organizations
                  </span>
                </a>
              </div>
            </div>
          </NavLink>
        </div>
        <div className="mx-5 ">
          <div className="border border-sideNavButtonBg w-full mb-5 rounded flex flex-col">
            <div className="bg-mainBackground flex items-center justify-between h-16 px-4">
              <div className="flex  items-center justify-center">
                <div className="">
                  <img className="w-6 h-6" src={AdminAvatar} alt="*" />
                </div>
                <div className="flex flex-col pl-[22px] font-semibold text-[#344054] text-sm">
                  Admin
                </div>
              </div>
              <div
                className="flex  items-center justify-center cursor-pointer"
                onClick={() => setIsChangePasswordOpen(true)}
                data-tooltip-id="change-password-tooltip"
                data-tooltip-content="Change password"
              >
                <img
                  className="w-6 h-6"
                  src={ChangePasswordIcon}
                  alt="changePassword"
                />
                <Tooltip id="change-password-tooltip" />
              </div>
            </div>

            <div
              className="flex items-center justify-start px-5 h-11 cursor-pointer"
              onClick={handleLogout}
            >
              <div className="">
                <img src={LogoutIcon} alt="*" />
              </div>
              <p className="font-Inter text-sm text-greyBlackLightColor font-semibold pl-2">
                Sign Out
              </p>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isChangePasswordOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        className="mx-auto rounded-lg shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <ChangePassword setIsOpen={setIsChangePasswordOpen} />
      </Modal>
    </Fragment>
  );
};

export default SideNav;
