import { useState, useMemo } from 'react';
import { useStore } from '@/hooks/useRedux';
import { NavLink } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';

import ToggleHandler from './ToggleHandler';

import { Plan } from '../../interface/organization.interface';

import { SubscriptionTypeEnums } from '@/src/enums/enums';

import LOSSettings from '@/src/assets/imagesComponents/LOSSettings';
import { SETTINGS_LOS } from '@/src/routes/routesConstants';

const PlanItem = ({
  plan,
  loading,
  showDescription = false,
}: {
  plan?: Plan;
  loading?: boolean;
  showDescription?: boolean;
}): JSX.Element => {
  return (
    <div className="p-5">
      <div className="flex w-full flex-col  mr-4 h-[150px] rounded p-4 bg-[#21BD7A1A]">
        {loading ? (
          <Skeleton />
        ) : (
          <span className="text-base text-greyBlackColor font-semibold mb-3">
            {plan?.plan_name}
          </span>
        )}
        <div className="flex ">
          {loading ? (
            <Skeleton />
          ) : (
            <span className="text-3xl text-[#393939] font-semibold">
              ${plan?.price} <span className="text-base">/month</span>
            </span>
          )}
        </div>
        {(showDescription || loading) && (
          <span
            className={
              'text-sm text-[#8B93A4] font-medium pt-2 border-[#21BD7A80] ' +
              loading
                ? ''
                : 'border-t'
            }
          >
            {
              // plan?.users_limit
              loading ? (
                <Skeleton />
              ) : (
                <span>
                  Features for up to{' '}
                  {plan?.has_unlimited_users ? 'Unlimited' : plan?.users_limit}{' '}
                  users
                </span>
              )
            }
          </span>
        )}
      </div>
    </div>
  );
};

const OrganizationPlan = ({
  organization_id, organization_name
}: {
  organization_id: any;
  organization_name: any;
}): JSX.Element => {
  const [active, setActive] = useState('Organization Price');

  const { state } = useStore();

  const { orgPlans, orgPlansLoading, orgDetails } = state.organizations;

  const hasMultiplePlans = useMemo(() => {
    return [SubscriptionTypeEnums.ORGANIZATION_ADMIN_AND_LOAN_OFFICER].includes(
      orgDetails?.organization_payment_type?.code as SubscriptionTypeEnums
    );
  }, [orgDetails]);

  const currentPlan = useMemo(() => {
    if (orgPlans.length === 0) return null;

    if (hasMultiplePlans) {
      if (active === 'Organization Price') {
        return orgPlans.find(plan => plan.plan_for === 'ORGADMIN');
      }

      return orgPlans.find(plan => plan.plan_for === 'USER');
    }

    return orgPlans[0];
  }, [active, orgPlans]);

  return (
    <div className="flex flex-col">
      <div className="shadow-pageShadow bg-white h-full rounded-lg ml-4 font-Inter">
        <div className="flex items-center justify-between pt-4 pb-3 px-6 border-b border-sideNavButtonBg">
          <span className="text-lg text-greyBlackColor font-semibold">
            Plans
          </span>
        </div>
        <div className="mt-4">
          {orgPlansLoading && <PlanItem loading />}
          {!orgPlansLoading && hasMultiplePlans && (
            <div className="w-full flex justify-center px-5">
              <ToggleHandler
                options={['Organization Price', 'Loan Officer Price']}
                current={active}
                setCurrentTab={setActive}
              />
            </div>
          )}

          {!orgPlansLoading && !hasMultiplePlans && (
            <h1 className="ml-5 text-[#667085] font-Inter font-semibold">
              {orgDetails?.organization_payment_type?.code ===
                SubscriptionTypeEnums.LOAN_OFFICER && 'Loan Officer Price'}
              {orgDetails?.organization_payment_type?.code ===
                SubscriptionTypeEnums.ORGANIZATION_ADMIN &&
                'Organization Price'}
            </h1>
          )}

          {currentPlan && <PlanItem plan={currentPlan} />}
          {/* <PlanItem /> */}
        </div>
      </div>
      <NavLink to={`${SETTINGS_LOS}/${organization_id}?organization_name=${encodeURIComponent(organization_name)}`}>
        <div
          className={`flex justify-center py-3 mt-10 rounded bg-white ml-3 cursor-pointer
           usersListBtn hover:text-white hover:bg-black border border-black text-black 
           orgSettingsBtn orgSettingsBtn`}
        >
          <div className="mr-2">
            <LOSSettings />
          </div>
          LOS Settings
        </div>
      </NavLink>
    </div>
  );
};

export default OrganizationPlan;
