import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';
import {
  OrganizationDetailsTypes,
  OrganizationListBody,
} from '../../interface/organization.interface';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { RootState } from '@/store/index';
import organizationSlice from '../../store/slice/organization.slice';

import { Tooltip } from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-loading-skeleton/dist/skeleton.css';
import Modal from 'react-modal';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import useDebounce from '@/hooks/useDebounce';

import SearchInput from '@/components/Search';
import TableRowHeader from '@/components/Table';
import NoOrganizationsFound from './NoOrganizationsFound';
import ResendInvite from './ResendInvite';
import RemoveOrganization from './RemoveOrganization';
import Pagination from '@/components/Pagination';

import {
  capitalizeFirstLetter,
  convertDateToHoursOrSeconds,
  formatDate,
  randomBGColor,
  randomTextColor,
} from '@/lib/helper';

import { LIMIT } from '@/lib/constants';
import { ORGANIZATIONS_DETAILS } from '@/src/routes/routesConstants';

import NoDataIcon from '../../../../assets/images/svg/noCustomer_icon.svg';
import MenuVerticalIcon from '../../../../assets/images/svg/menu-vertical_icon.svg';
import DropDownIcon from '../../../../assets/images/svg/down-grey_icon.svg';

const OrganizationsList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // Redux states
  const { organizationList, isLoading, organizationListBody } = useAppSelector(
    (state: RootState) => state.organizations
  );
  const { resendInvite } = useAppSelector(
    (state: RootState) => state.settings.modals
  );

  // Local states
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [sort, setSort] = useState<string>('accepted_at');
  const [order, setOrder] = useState<string>('DESC');
  const [status, setStatus] = useState<string[]>(['active']);
  const [showDropDown, setShowDropDown] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false);
  const [showToolTip, setShowToolTip] = useState<number | null>(null);
  const [resendEmailBody, setResendEmailBody] = useState<{
    organization_id: string;
    email: string;
  }>({
    organization_id: '',
    email: '',
  });
  const [removeUserId, setRemoveUserId] = useState<string>('');

  // Custom hook
  const debouncedValue = useDebounce(searchQuery, 500);

  // Return the length of organization list rows array
  const organizationListLength = (): number => {
    return organizationList?.rows?.length;
  };

  const limit = LIMIT;
  const offset = currentPage * limit;
  const totalPages = Math.ceil(organizationList?.count / limit);
  const indexOfLastItem = offset + organizationListLength();

  const dropdownRef: any = useRef(null);
  const pageRef = useRef<HTMLInputElement>(null);
  const [toolTipRefs, setToolTipRefs] = useState(
    Array(organizationListLength()).map(() => createRef<HTMLDivElement>())
  );

  useEffect(() => {
    setToolTipRefs(elRefs =>
      Array(organizationListLength())
        .fill(null)
        .map((_, i) => elRefs[i] || createRef<HTMLDivElement>())
    );
  }, [organizationList.rows]);

  const toggleToolTip = (
    e: React.MouseEvent<HTMLImageElement>,
    index: number
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setShowToolTip(index);
  };

  useEffect(() => {
    // Add event listener to document when the dropdown is shown
    if (showDropDown) {
      document.addEventListener('click', handleClickOutside);
    }
    if (showToolTip !== null) {
      document.addEventListener('click', handleClickOutside);
    }
    // Clean up the event listener when the component unmounts or the dropdown is hidden
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showDropDown, showToolTip]);

  const toggleDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  // Handle body data for all functionalities and fire the organizationList api accordingly
  useMemo(() => {
    dispatch(
      organizationSlice.actions.setOrganizationListBody({
        page: currentPage,
        search: debouncedValue,
        order: order,
        sort: sort,
        status: status,
      })
    );
  }, [currentPage, debouncedValue, order, sort, status]);

  useMemo(() => {
    if (organizationListBody) {
      dispatch(
        organizationSlice.actions.getOrganizationsList(
          organizationListBody as OrganizationListBody
        )
      );
    }
  }, [organizationListBody]);

  useEffect(() => {
    return () => {
      dispatch(organizationSlice.actions.setOrganizationListBody(null));
    };
  }, []);

  // Check if the click is outside the dropdown
  const handleClickOutside = (event: { target: any }) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropDown(false);
    }
    if (
      showToolTip !== null &&
      toolTipRefs &&
      toolTipRefs?.length &&
      !toolTipRefs?.some((toolTipRef: any) =>
        toolTipRef.current?.contains(event.target)
      )
    ) {
      setShowToolTip(null);
    }
  };

  // Open or close the resend invite modal
  const handleResendInviteModal = (isOpen: boolean) => {
    dispatch(settingSlice.actions.setResendInviteModalState(isOpen));
  };

  // Handle status filter dropdown values
  const handleOptionSelect = (value: string) => {
    setCurrentPage(0);
    switch (value) {
      case 'active':
        setStatus(['active']);
        break;
      case 'invited':
        setStatus(['invited']);
        break;
      case 'pending':
        setStatus(['pending']);
        break;
      case 'expired':
        setStatus(['expired']);
        break;
      case 'all':
        setStatus([]);
        break;
      default:
        break;
    }
    setShowDropDown(false);
  };

  // To handle displaying the appropriate filter status on the screen as per toggle
  const handleDisplayStatusOnClick = (stat: string[]): string => {
    if (stat.includes('invited') && stat.length === 1) {
      return 'invited';
    } else if (stat.includes('active') && stat.length === 1) {
      return 'active';
    } else if (stat.includes('pending') && stat.length === 1) {
      return 'pending';
    } else if (stat.includes('expired') && stat.length === 1) {
      return 'expired';
    } else {
      return 'all';
    }
  };

  // Handle pagination
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  // Handle skip to page
  const handleGoToPage = () => {
    if (
      pageRef.current &&
      parseInt(pageRef.current?.value) <= totalPages &&
      parseInt(pageRef.current?.value) > 0
    )
      setCurrentPage(parseInt(pageRef.current?.value) - 1);
  };

  const handleSearch = (value: string): void => {
    setCurrentPage(0);
    setSearchQuery(value);
  };

  const RemoveOrganizationModal = () => {
    setIsRemoveModalOpen(true);
    setShowToolTip(null);
  };

  const handleOrganizationDetails = (
    e: React.MouseEvent<HTMLDivElement>,
    id: string,
    organization_name: string
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    navigate(
      {
        pathname: `${ORGANIZATIONS_DETAILS}/${id}`,
        search: createSearchParams({
          organization_name: capitalizeFirstLetter(organization_name),
        }).toString(),
      },
      { state: { userId: id } }
    );
  };

  const tableRows: typeof isLoading extends true
    ? any[]
    : OrganizationDetailsTypes[] = useMemo(
      () => (isLoading ? Array(10).fill(1) : organizationList?.rows ?? []),
      [isLoading, organizationList.rows]
    );

  // To reset the page when all the entries in a page are deleted
  useEffect(() => {
    if (
      !organizationList.rows.length &&
      !searchQuery.length &&
      currentPage !== 0
    ) {
      setCurrentPage(0);
    }
  }, [organizationList.rows, searchQuery, currentPage]);

  return (
    <div className="flex flex-col h-screen font-Inter">
      <div className="p-4 flex justify-between items-center">
        <div className="w-[460px]">
          <SearchInput
            name="search"
            placeholder="Search the organizations by name or email address"
            value={searchQuery}
            onSearch={handleSearch}
            autoFocus={true}
          />
        </div>
        <div
          className={`relative flex bg-mainBackground text-xs font-medium border border-mainBackground text-greyBlackLightColor rounded h-[33px] w-[139px] w-min-[200px] items-center px-4 justify-between
        ${showDropDown ? 'border-greyBlackLightColor' : ''}
        `}
          ref={dropdownRef}
        >
          <span className="">Status: </span>
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={toggleDropDown}
          >
            <span className="pl-2 capitalize">
              {handleDisplayStatusOnClick(status)}
            </span>
            <img className="pl-3" src={DropDownIcon} alt="" />
          </div>
          {showDropDown && (
            <div className="absolute shadow-card rounded-md top-9 left-0 bg-white w-full z-10">
              <div className="flex flex-col text-xs font-medium text-greyBlackColor text-right ">
                <span
                  className={` px-4 py-2 cursor-pointer ${!status.length ? 'text-blackLightColor font-semibold' : ''
                    }`}
                  onClick={() => handleOptionSelect('all')}
                >
                  All
                </span>
                <span
                  className={` px-4 py-2 cursor-pointer ${status[0] === 'active'
                      ? 'text-blackLightColor font-semibold'
                      : ''
                    }`}
                  onClick={() => handleOptionSelect('active')}
                >
                  Active
                </span>
                <span
                  className={` px-4 py-2 cursor-pointer ${status[0] === 'invited'
                      ? 'text-blackLightColor font-semibold'
                      : ''
                    }`}
                  onClick={() => handleOptionSelect('invited')}
                >
                  Invited
                </span>
                <span
                  className={` px-4 py-2 cursor-pointer ${status[0] === 'pending'
                      ? 'text-blackLightColor font-semibold'
                      : ''
                    }`}
                  onClick={() => handleOptionSelect('pending')}
                >
                  Pending
                </span>
                <span
                  className={` px-4 py-2 cursor-pointer ${status[0] === 'expired'
                      ? 'text-blackLightColor font-semibold'
                      : ''
                    }`}
                  onClick={() => handleOptionSelect('expired')}
                >
                  Expired
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col ">
        <div className="flex w-full items-center bg-interLightBlack px-4 h-8 border-l border-r border-white">
          {Boolean(organizationListLength() || isLoading) && (
            <div className="flex w-full">
              <div className="w-2/5">
                <TableRowHeader
                  title={'Organization'}
                  sort={true}
                  setOrder={setOrder}
                  setSort={setSort}
                  order={order}
                  textColor={'white'}
                  className={''}
                />
              </div>
              <div className="w-1/5">
                <TableRowHeader
                  title={'Admin email'}
                  sort={false}
                  textColor={'white'}
                  className={''}
                />
              </div>
              <div className="w-1/5">
                <TableRowHeader
                  title={'Status'}
                  sort={false}
                  textColor={'white'}
                  className={''}
                />
              </div>
              <div className="w-1/5 flex justify-center">
                <TableRowHeader
                  title={'Date joined'}
                  sort={true}
                  setOrder={setOrder}
                  setSort={setSort}
                  order={order}
                  textColor={'white'}
                  className={''}
                />
              </div>
              <div className="w-5 flex justify-center">
                <TableRowHeader
                  title={''}
                  sort={false}
                  textColor={'white'}
                  className={''}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col table-layout overflow-y-auto border-l border-r border-white">
          {organizationListLength() || isLoading ? (
            <div>
              {tableRows?.map((item, index) => (
                <div
                  key={index}
                  onClick={e =>
                    !isLoading && handleOrganizationDetails(e, item?.id, item?.name)
                  }
                  className="flex border-b border-sideNavButtonBg w-full items-center justify-between h-14 min-h-[56px] px-4 hover:bg-sideNavButtonBg cursor-pointer"
                >
                  <div className="flex items-center w-2/5">
                    {!isLoading ? (
                      <div className="flex items-center w-full">
                        <div
                          className="h-33 w-33 rounded-md flex items-center justify-center font-semibold"
                          style={{ backgroundColor: randomBGColor(item?.name) }}
                        >
                          <span
                            className="uppercase text-xs"
                            style={{ color: randomTextColor() }}
                          >
                            {item?.name
                              .split(' ')
                              .map(namePart => namePart.charAt(0))
                              .join('')}
                          </span>
                        </div>
                        <span className="text-sm font-semibold text-interBlack capitalize pl-2 truncate w-[90%]">{`${capitalizeFirstLetter(
                          item?.name
                        )}`}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <Skeleton
                          count={1}
                          width={34}
                          height={33}
                          borderRadius={6}
                        />
                        <Skeleton
                          count={1}
                          width={140}
                          height={18}
                          borderRadius={10}
                          className="ml-3"
                        />
                      </div>
                    )}
                  </div>
                  <div className="w-1/5 flex items-center ">
                    {!isLoading ? (
                      <span className="text-sm font-medium text-greyWhiteColor lowercase">
                        {item?.admin_user?.email?.toLocaleLowerCase()}
                      </span>
                    ) : (
                      <Skeleton
                        count={1}
                        width={130}
                        height={18}
                        borderRadius={10}
                      />
                    )}
                  </div>
                  <div
                    className="w-1/5 flex items-center "
                  // onClick={e => handleOrganizationDetails(e, item?.id)}
                  >
                    {!isLoading ? (
                      <div
                        className={` font-medium rounded px-4 ${item?.status === 'invited' ||
                            item?.status === 'inactive' ||
                            item?.status === 'pending'
                            ? ' bg-[#34405433] text-interBlack'
                            : 'bg-[#CEF3E2] text-[#007B22]'
                          } `}
                      >
                        <span className="text-sm capitalize">
                          {item?.status}
                        </span>
                      </div>
                    ) : (
                      <Skeleton
                        count={1}
                        width={70}
                        height={18}
                        borderRadius={10}
                      />
                    )}
                  </div>

                  <div className="w-1/5 flex items-center justify-center">
                    {!isLoading ? (
                      <span
                        className="text-sm font-medium text-greyBlackColor cursor-pointer"
                        data-tooltip-id={`date-tooltip-${index}`}
                        data-tooltip-content={
                          item?.accepted_at
                            ? formatDate(String(item?.accepted_at), true)
                            : '--'
                        }
                      >
                        {item?.accepted_at
                          ? convertDateToHoursOrSeconds(
                            new Date(item?.accepted_at)
                          )
                          : '--'}
                      </span>
                    ) : (
                      <Skeleton
                        count={1}
                        width={120}
                        height={18}
                        borderRadius={10}
                      />
                    )}
                    <Tooltip
                      id={`date-tooltip-${index}`}
                      hidden={!item?.accepted_at}
                    />
                  </div>
                  {!isLoading && (
                    <div
                      className="w-5 flex justify-center relative"
                      ref={toolTipRefs[index]}
                    >
                      {(item?.status.toLocaleLowerCase().trim() === 'invited' ||
                        item?.status.toLocaleLowerCase().trim() ===
                        'pending') && (
                          <img
                            src={MenuVerticalIcon}
                            alt=""
                            className="cursor-pointer"
                            onClick={e => {
                              setResendEmailBody({
                                organization_id: item?.id,
                                email: item?.admin_user?.email,
                              });
                              toggleToolTip(e, index);
                            }}
                          />
                        )}

                      {showToolTip === index && (
                        <div className="absolute shadow-card rounded -left-36 top-8 z-10 bg-white w-40">
                          <div className="flex flex-col text-sm font-normal ">
                            {item?.status.toLocaleLowerCase().trim() ===
                              'invited' && (
                                <div>
                                  <span
                                    className=" cursor-pointer flex items-center text-blackLightColor px-2 pt-2"
                                    onClick={() => {
                                      handleResendInviteModal(true);
                                      setShowToolTip(null);
                                    }}
                                  >
                                    Resend Invite
                                  </span>
                                  <span
                                    className=" cursor-pointer flex items-center text-redErrorColor p-2"
                                    onClick={() => {
                                      setRemoveUserId(item?.id);
                                      setShowToolTip(null);
                                      RemoveOrganizationModal();
                                    }}
                                  >
                                    Remove Organization
                                  </span>
                                </div>
                              )}
                            {item?.status?.toLocaleLowerCase().trim() ===
                              'pending' && (
                                <span
                                  className="h-9 cursor-pointer flex items-center justify-center text-redErrorColor"
                                  onClick={() => {
                                    setRemoveUserId(item?.id);
                                    RemoveOrganizationModal();
                                  }}
                                >
                                  Remove Organization
                                </span>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : !!debouncedValue ? (
            <NoOrganizationsFound searchInput={debouncedValue} />
          ) : (
            <div className="flex flex-col h-full  items-center">
              <img
                className="mt-20 w-[163px] h-[71px]"
                src={NoDataIcon}
                alt=""
              />
              <h2 className="text-interBlack text-xl font-medium py-2">
                No organizations are listed yet
              </h2>

              <span className="text-greyBlackColor text-sm font-medium text-center">
                Click “Add Organization” to add the details
                <br /> of organization
              </span>
            </div>
          )}
        </div>

        {organizationList?.count > limit && (
          <Pagination
            arrayList={organizationList}
            currentPage={currentPage}
            indexOfLastItem={indexOfLastItem}
            limit={limit}
            offset={offset}
            pageRef={pageRef}
            totalPages={totalPages}
            setCurrentPage={setCurrentPage}
            showButtons={true}
            isLoading={isLoading}
          />
        )}
      </div>
      <Modal
        isOpen={isRemoveModalOpen}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <RemoveOrganization
          body={organizationListBody!!}
          removeUserId={removeUserId}
          setIsRemoveModalOpen={setIsRemoveModalOpen}
        />
      </Modal>
      <Modal
        isOpen={resendInvite}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <ResendInvite resendEmailBody={resendEmailBody} />
      </Modal>
    </div>
  );
};

export default OrganizationsList;
