import { TopNavData } from '@/modules/Settings/interface/settings.interface';
import { ENDPOINT_TYPES } from '../interface/interface';
import { AuthMeResponseTypes } from '@/modules/Authentication/interface/auth.interface';
import {
  UserDetailsTypes,
  UsersListTypes,
} from '@/modules/Users/interface/users.interface';
import {
  OrganizationListBody,
  OrganizationListTypes,
} from '@/modules/Organizations/interface/organization.interface';

// TopNav headings and button texts
export const CUSTOMERS_DATA: TopNavData = {
  heading: 'Customers',
  buttonText: 'Add Customer',
};
export const REPORT_DATA: TopNavData = {
  heading: 'Reports',
  buttonText: 'Add Report',
};
export const USERS_DATA: TopNavData = {
  heading: 'Users',
  buttonText: 'Add User',
};

export const ORGANIZATIONS_DATA: TopNavData = {
  heading: 'Organizations',
  buttonText: 'Add Organization',
};

// Miscellaneous
export const COUNTRY_CODE: string = '+1';
export const LIMIT: number = 10;
export const MONTH_NAMES: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const DETAILS: string = 'details';
export const LOAN_OFFICER: string = 'Loan Officer';
export const AUTH_ME_DATA: AuthMeResponseTypes = {
  email: '',
  id: '',
  role: {
    id: '',
    name: '',
  },
  user_profile: {
    country_code: '',
    first_name: '',
    last_name: '',
    nmls: '',
    phone_number: '',
  },
};

// Endpoints
export const ENDPOINTS: ENDPOINT_TYPES = {
  ADMIN: 'admin',
  AUTH: 'auth',
  CUSTOMER: 'customer',
  STATE: 'states',
  PROPOSAL: 'proposal',
  ORGANIZATION: 'organization',
  ORGANIZATIONS: 'organizations',
  USER: 'user',
  USERS: 'users',
  ROLES: 'roles',
  SETTINGS: 'settings'
};

// User roles
export const USER_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ORGANIZATION_ADMIN: 'Organization Admin',
  ORGANIZATION_USER: 'Organization User',
  LOAN_OFFICER: 'Loan Officer',
};

export const VALIDATE_USER_DETAILS = {
  first_name: '',
  email: '',
  invitation_key: '',
  password: '',
};

export const USERS_LIST_INITIAL_VALUES: UsersListTypes = {
  count: 0,
  rows: [],
  totalCount: 0,
  isEmpty: false,
};

export const ORGANIZATION_LIST_INITIAL_VALUES: OrganizationListTypes = {
  count: 0,
  rows: [],
  isEmpty: false,
  totalCount: 0,
};

export const ORGANIZATION_LIST_BODY_INITIAL_VALUES: OrganizationListBody = {
  order: 'DESC',
  page: 0,
  search: '',
  sort: 'accepted_at',
  status: ['active'],
};

// Success messages
export const ACCOUNT_SETUP_SUCCESS_MESSAGE: string =
  'Account set up successfully';
export const REMOVE_ORGANIZATION_SUCCESS_MSG: string =
  'Removed Organization successfully';

export const PASSWORD_RESET_MAIL_SENT_SUCCESS_MSG: string =
  'Reset password mail sent';

export const PASSWORD_RESET_SUCCESS_MSG: string =
  'The password was reset successfully';

export const RESENT_PASSWORD_SUCCESS_MESSAGE = 'Email sent successfully';
export const PAYMENT_TYPE_VALIDATION_MESSAGE =
  'A payment type should be selected to continue';
export const ADD_ORGANIZATION_INITIAL_VALUES = {
  organization_id: null,
  organization_payment_type_details: null,
};
export const addOrganizationModalHeadings = {
  addOrganization: {
    heading: 'Add Organization',
    subHeading: 'Enter Organization Details',
  },
  subscriptionType: {
    heading: 'Subscription Choice',
    subHeading: 'Select the payment type',
  },
  subscriptionPlan: {
    heading: 'Subscription Plans',
    subHeading: 'Add the details of subscription plans',
  },
};
export const INVITE_ORGANIZATION_SUCCESS_TOAST_MESSAGE =
  'Organization account created and invitation sent successfully';

export const SPECIAL_KEYS = [
  'Backspace',
  'Delete',
  'Escape',
  'Tab',
  'ArrowDown',
  'ArrowUp',
  'ArrowLeft',
  'ArrowRight',
];


export const ALLOWED_IMAGE_FILE_TYPES: string =
  'image/png, image/jpeg, image/jpg';
export const ALLOWED_IMAGE_FILE_TYPES_ARRAY: string[] = [
  'image/png',
  'image/jpeg',
  'image/jpg',
];

export const REMOVE_LOGO_SUCCESS: string = 'Logo removed successfully';
export const LOGO_UPDATED_SUCCESS_MSG: string = 'Logo updated successfully';