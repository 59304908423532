import { useState } from 'react';
import EditIcon from '../../../../assets/images/svg/edit_black_icon.svg';
import Modal from 'react-modal';
import EditOrganization from './EditOrganization';
import { useAppSelector } from '@/hooks/useRedux';
import { formatPhoneNumber } from '@/lib/helper';

const OrganizationView: React.FC<{ organization_id: any }> = ({ organization_id }): JSX.Element => {

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const settingsDetails = useAppSelector(
    state => state.settings.organizationDetails
  );

  const organizationSettings = settingsDetails?.organization_settings;


  const editOrganizationModel = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div className="px-6 py-5 flex flex-col shadow-pageShadow bg-white mb-4 rounded-lg ">
      <div className="flex justify-between items-center  pb-2">
        <span className="text-sm font-medium text-greyBlackColor">
          Organization Details
        </span>

        <button
          onClick={editOrganizationModel}
          className="flex justify-center items-center  rounded font-semibold bg-white edit-icon-bg"
        >
          <img src={EditIcon} className="w-[16px]" alt="" />
        </button>
      </div>

      <div className="border-t border-sideNavButtonBg flex pt-3">
        <div className="w-1/2 flex flex-col">
          <div className="flex flex-col">
            <span className="text-xs font-medium text-greyDarkBlackColor">
              Name
            </span>
            <span className="text-sm font-medium text-blackLightColor pt-1 capitalize">
              {settingsDetails?.name}
            </span>
          </div>
          <div className="flex flex-col mt-4">
            <span className="text-xs font-medium text-greyDarkBlackColor">
              Phone
            </span>
            <span className="text-sm font-medium text-blackLightColor pt-1 capitalize">
              {settingsDetails?.organization_settings.phone_number
                ? `${formatPhoneNumber(
                  settingsDetails?.organization_settings.phone_number
                )}
              `
                : '--'}
            </span>
          </div>
        </div>
        <div className="w-1/2">
          <div className="flex flex-col">
            <span className="text-xs font-medium text-greyDarkBlackColor">
              NMLS
            </span>
            <span className="text-sm font-medium text-blackLightColor pt-1 capitalize">
              {settingsDetails?.organization_settings.nmls}
            </span>
          </div>
          <div className="flex flex-col mt-4">
            <span className="text-xs font-medium text-greyDarkBlackColor">
              Address
            </span>
            <span className="text-sm font-medium text-blackLightColor pt-1 capitalize">
              {organizationSettings?.address_line_1 ? (
                <>
                  {organizationSettings.address_line_1}
                  {organizationSettings?.address_line_2 && (
                    <>, {organizationSettings.address_line_2}</>
                  )}
                  {organizationSettings?.city && (
                    <>, {organizationSettings.city}</>
                  )}
                  {organizationSettings?.country && (
                    <>, {organizationSettings.country}</>
                  )}
                </>
              ) : (
                '--'
              )}
            </span>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="mx-auto rounded-lg shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <EditOrganization handleClose={() => closeModal()} organization_id={organization_id} />
      </Modal>
    </div>
  );
};

export default OrganizationView;
