import { StateListTypes } from "@/modules/Customer/interface/customer.interface";
import { StateListService } from "@/modules/Customer/services/customer.service";
import { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

const AutoCompleteInput = ({ errorInput, selectedState, setSelectedState, setTouched }: any) => {
  useEffect(() => {
    handleStatesList();
  }, []);

  const [stateList, setStateList] = useState<StateListTypes[]>([]);

  const handleStatesList = async () => {
    const params = { sort: "DESC", page: 0, limit: 60 };
    const response: StateListTypes[] = await StateListService(params as typeof params);
    const uniqueStates = response.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.name === item.name)
    );
    setStateList(uniqueStates);
  };

  const items: StateListTypes[] = stateList;

  const handleOnSelect = (item: StateListTypes): void => {
    setSelectedState(item);
  };

  const handleOnFocus = (): void => {
    setTouched();
    handleStatesList();
  };

  const formatResult = (item: any): JSX.Element => {
    return (
      <>
        <span style={{ display: "block", textAlign: "left" }}> {item.name}</span>
      </>
    );
  };
  return (
    <div className="">
      <ReactSearchAutocomplete
        items={items}
        inputSearchString={selectedState ?? ""}
        onSelect={handleOnSelect}
        onFocus={handleOnFocus}
        fuseOptions={{
          shouldSort: true,
          threshold: 0,
          keys: ["name"],
        }}
        formatResult={formatResult}
        placeholder="Search and select state"
        className={`auto-complete ${errorInput}`}
      />
    </div>

  );
};

export default AutoCompleteInput;
