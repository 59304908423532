import { Dispatch, useEffect, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { NavigateFunction, useNavigate, useParams } from 'react-router';

import { AnyAction } from 'redux';
import Modal from 'react-modal';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { RootState } from '@/store/index';
import settingSlice from '@/modules/Settings/store/slice/settings.slice';
import AddOrganization from '@/modules/Organizations/pages/Components/AddOrganization';
import DownloadUserModal from './DownloadUserModal';
import { TopNavData } from '@/modules/Settings/interface/settings.interface';

import {
  DETAILS,
  ORGANIZATIONS_DATA,
  USER_ROLES,
  USERS_DATA,
} from '@/lib/constants';
import {
  ORGANIZATIONS,
  ORGANIZATIONS_DETAILS,
  SETTINGS,
  USERS,
} from '@/src/routes/routesConstants';

import RightArrowIcon from '../../assets/images/svg/right_arrow.svg';
import { useStore } from 'react-redux';

const TopNav = (): JSX.Element => {
  const [showReportModal, setShowReportModal] = useState(false);

  const pagePath: string = window.location.pathname;
  const userRole: string | null = useAppSelector(
    (state: RootState) => state.auth.authMeData.role.name
  );

  const dispatch: Dispatch<AnyAction> = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();

  const [params] = useSearchParams();
  const organization_name = params.get('organization_name');
  const organization_id = params.get('id');
  const { id } = useParams();

  const org_id = id || organization_id;




  const { topNavData, modals } = useAppSelector(
    (state: RootState) => state.settings
  );

  const [isBreadCrumbs, setIsBreadCrumbs] = useState<boolean>(false);

  useEffect(() => {
    if (pagePath.includes(DETAILS) || pagePath.includes(SETTINGS)) {
      setIsBreadCrumbs(true);
    } else {
      setIsBreadCrumbs(false);
    }
  }, [pagePath]);

  const handleButtonComponent = (role: string) => {
    switch (role) {
      case USER_ROLES.SUPER_ADMIN:
        return <AddOrganization />;

      default:
        break;
    }
  };

  const displayHeadersBasedOnRoute = (): void => {
    if (pagePath.includes(USERS))
      dispatch(
        settingSlice.actions.setTopNavData({
          heading: USERS_DATA.heading,
          buttonText: USERS_DATA.buttonText,
        } as TopNavData)
      );
    else
      dispatch(
        settingSlice.actions.setTopNavData({
          heading: ORGANIZATIONS_DATA.heading,
          buttonText: ORGANIZATIONS_DATA.buttonText,
        } as TopNavData)
      );
  };

  useEffect(() => {
    displayHeadersBasedOnRoute();
  }, [pagePath]);

  const handleNavigateToOrgDetailsPage = (): void => {
    navigate({
      pathname: `${ORGANIZATIONS_DETAILS}/${org_id}`,
      search: createSearchParams({
        organization_name: organization_name!,
      }).toString(),
    });
  };

  const handleNavigateToSettingsPage = (): void => {
    navigate({
      pathname: `${ORGANIZATIONS_DETAILS}/${org_id}`,
      search: createSearchParams({
        organization_name: organization_name!,
      }).toString(),
    });
  };

  return (
    <div className="flex justify-between items-center">
      <div>
        <h2 className="font-semibold text-2xl font-Inter text-blackDarkColor">
          {topNavData.heading}
        </h2>

        {isBreadCrumbs && (
          <div className="flex items-center mt-2">
            <h6
              className="font-medium text-sm font-Inter text-gray-500 mr-3 cursor-pointer"
              onClick={() => {
                navigate(ORGANIZATIONS);
              }}
            >
              Organizations
            </h6>
            <div className="w-5">
              <img src={RightArrowIcon} alt="*" />
            </div>
            <h6
              className="font-medium text-sm font-Inter cursor-pointer"
              style={{ marginLeft: '0.5em' }}
              onClick={() => {
                if (pagePath.includes(USERS)) handleNavigateToOrgDetailsPage();
                if (pagePath.includes(SETTINGS)) handleNavigateToSettingsPage();
              }}
            >
              {organization_name ? organization_name : 'organization'}
            </h6>
            {pagePath.includes(USERS) && (
              <>
                <div className="w-5 ml-3">
                  <img src={RightArrowIcon} alt="*" />
                </div>
                <h6
                  className="font-medium text-sm font-Inter"
                  style={{ marginLeft: '0.5em' }}
                >
                  User List
                </h6>
              </>
            )}
            {pagePath.includes('settings') && (
              <>
                <div className="w-5 ml-3">
                  <img src={RightArrowIcon} alt="*" />
                </div>
                <h6
                  className="font-medium text-sm font-Inter"
                  style={{ marginLeft: '0.5em' }}
                >
                  Settings
                </h6>
              </>
            )}
          </div>
        )}
      </div>

      <Modal
        isOpen={modals.addOrganization}
        ariaHideApp={false}
        className="mx-auto  rounded shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        {handleButtonComponent(userRole as string)}
      </Modal>
      <DownloadUserModal
        open={showReportModal}
        onRequestClose={() => setShowReportModal(false)}
      />
    </div>
  );
};

export default TopNav;
