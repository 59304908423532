import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LosSettingsPageLoader = () => {
  return (
    <div>
      <Skeleton height={50} />
      <Skeleton height={50} className="mt-14" />
      <Skeleton height={50} className="mt-8" />
      <Skeleton height={222} className="mt-4" />
    </div>
  );
};

export default LosSettingsPageLoader;
