import React from 'react'

const EditIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3474_2606)">
<path d="M8.25 3.00017H3C2.60218 3.00017 2.22064 3.15821 1.93934 3.43951C1.65804 3.72081 1.5 4.10235 1.5 4.50017V15.0002C1.5 15.398 1.65804 15.7795 1.93934 16.0608C2.22064 16.3421 2.60218 16.5002 3 16.5002H13.5C13.8978 16.5002 14.2794 16.3421 14.5607 16.0608C14.842 15.7795 15 15.398 15 15.0002V9.75017M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_3474_2606">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>

  )
}

export default EditIcon