import { BASE_URL } from '@/lib/config';
import { ENDPOINTS } from '@/lib/constants';
import axiosInstance from '@/lib/interceptors';
import axios, { AxiosError } from 'axios';
import {
  BillingHistoryListBody,
  BillingHistoryListTypes,
  BillingInvoice,
  CheckLastLoginResponseTypes,
  CurrentPaymentDetailsResponse,
  CurrentPlanDetails,
  GetHECMProductsBody,
  LosConfigurations,
  LosConfigurationsRequestBody,
  ProductListEntity,
  SettingsDetailsTypes,
  UpdateOrganizationDetailsBody,
  UpdateOrganizationPrivacyPolicyBody,
} from '../interface/settings.interface';

export const GetPresignedURLService = async (body: {
  content_type: string;
  file_extension: string;
}, id: string) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/super-${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SETTINGS}/logo/${id}`,
      body
    );
    return response?.data?.data as { uploadURL: string; logo_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const UploadImageToS3Service = async (url: string, body: any) => {
  try {
    const response = await axios.put(
      `${url}`,
      body /* , { headers: { "Content-Type": 'multipart/form-data' } } */
    );
    return response;
  } catch (err) {
    throw (err as AxiosError).message;
  }
};

export const SendPresignedURLService = async (body: { logo: string }, id: string) => {
  try {
    const response = await axiosInstance.patch(
      `${BASE_URL}/super-${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SETTINGS}/logo/${id}`,
      body
    );
    return response?.status;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const RemoveLogoService = async (id: string): Promise<{
  uploadURL: string;
  logo_url: string;
}> => {
  try {
    const response = await axiosInstance.delete(
      `${BASE_URL}/super-${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SETTINGS}/logo/${id}`
    );
    return response?.data?.data as { uploadURL: string; logo_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetSettingsPageDetailsService =
  async (organization_id: string): Promise<SettingsDetailsTypes> => {
    try {
      console.log(organization_id)
      const response = await axiosInstance.get(
        `${BASE_URL}/super-${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SETTINGS}/${organization_id}`
      );
      return response?.data?.data as SettingsDetailsTypes;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };

export const GetCurrentPaymentMethodDetailsService =
  async (): Promise<CurrentPaymentDetailsResponse> => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/currentPaymentDetail`
      );
      return response?.data?.data as CurrentPaymentDetailsResponse;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };

export const GetCurrentPlanDetailsService =
  async (): Promise<CurrentPlanDetails> => {
    try {
      const response = await axiosInstance.get(
        `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/currentPlanAndPrice`
      );
      return response?.data?.data as CurrentPlanDetails;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };

export const CancelSubscriptionService = async (body: { back_url: string }) => {
  try {
    const config = {
      data: body,
    };
    const response = await axiosInstance.delete(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/cancel`,
      config
    );
    return response?.data?.data as { redirect_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const RenewSubscriptionService = async (body: { back_url: string }) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/renew`,
      body
    );
    return response?.data?.data as { redirect_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const ManagePaymentMethod = async (body: { back_url: string }) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/managePaymentMethod`,
      body
    );
    return response?.data?.data as { redirect_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const BillingHistoryListService = async (
  body: BillingHistoryListBody
): Promise<BillingHistoryListTypes> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/subscriptionPaymentHistory`,
      body
    );
    return response.data?.data as BillingHistoryListTypes;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};
export const GetBillInvoice = async (invoiceId: string) => {
  try {
    const response = await axiosInstance.get(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/invoicePdfUrl/${invoiceId}`
    );
    return response.data?.data as BillingInvoice;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetLosConfigurations = async (organization_id: string): Promise<LosConfigurations> => {
  try {
    const response = await axiosInstance.get(`${BASE_URL}/los-configuration/${organization_id}`);

    return response.data?.productList as LosConfigurations;

  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const GetHecmProducts = async (
  body: GetHECMProductsBody
): Promise<ProductListEntity[]> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.HECM_PRODUCT}/getHECMProducts`,
      body
    );
    return response.data?.data as ProductListEntity[];
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const SaveOrUpdateLosConfigurations = async (
  body: LosConfigurationsRequestBody
): Promise<string> => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/los-configuration/update-products/${body.los_settings.organization_id}`,
      body
    );
    return response.data?.message as string;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const UpdateOrganizationDetails = async (
  body: UpdateOrganizationDetailsBody
) => {
  try {

    const response = await axiosInstance.put(
      `${BASE_URL}/super-${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/settings/${body.organization_id}`,
      body
    );
    return response.data?.data;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const UpdateOrganizationPrivacyPolicy = async (
  body: UpdateOrganizationPrivacyPolicyBody
) => {
  try {
    const response = await axiosInstance.put(
      `${BASE_URL}/super-${ENDPOINTS.ADMIN}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SETTINGS}/privacy-policy/${body.organization_id}`,
      body
    );
    return response.data?.data;
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const AddPaymentMethodForLoanOfficer = async (body: {
  back_url: string;
}) => {
  try {
    const response = await axiosInstance.post(
      `${BASE_URL}/${ENDPOINTS.ORGANIZATION}/${ENDPOINTS.SUBSCRIPTION}/addPaymentMethod`,
      body
    );
    return response?.data?.data as { redirect_url: string };
  } catch (err) {
    throw (err as AxiosError).response?.data;
  }
};

export const checkLastLoginService =
  async (): Promise<CheckLastLoginResponseTypes> => {
    try {
      const response = await axiosInstance.put(
        `${BASE_URL}/${ENDPOINTS.AUTH}/update-last-login`
      );
      return response?.data?.data;
    } catch (err) {
      throw (err as AxiosError).response?.data;
    }
  };




