import dayjs from "dayjs";
import { COUNTRY_CODE, MONTH_NAMES } from "./constants";

const now = dayjs();

export const capitalizeFirstLetter = (value: string): string => {
  if (value) {
    const modifiedString = value?.charAt(0)?.toUpperCase() + value?.slice(1);
    return modifiedString;
  } else {
    return "--";
  }
};

export const calculateAge = (dob: Date | null): string => {
  if (!dob || isNaN(+new Date(dob))) {
    return "";
  }
  var today = new Date();
  var birthDate = new Date(dob);
  var age = today.getFullYear() - birthDate.getFullYear();
  var month = today.getMonth() - birthDate.getMonth();
  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return `${age} Yrs`;
};

export const randomBGColor = (firstLetter: string): string => {
  const bgColor = [
    "#FCE7F6",
    "#D1E9FF",
    "#EBE9FE",
    "#FFEAD5",
    "#FFE4E8",
    "#FCE7F6",
    "#D1E9FF",
    "#EBE9FE",
    "#FFEAD5",
    "#FFE4E8",
    "#FCE7F6",
    "#D1E9FF",
  ];
  const randomIndex = Math.floor((firstLetter?.toLowerCase().charCodeAt(0) - 97) % 8);
  const item = bgColor[randomIndex];
  return item;
};

export const randomTextColor = () => {
  const textColor = [
    "C11574",
    "#175CD3",
    "#5925DC",
    "#EC4A0A",
    "#E31B54",
    "#C11574",
    "#175CD3",
    "#5925DC",
    "#EC4A0A",
    "#E31B54",
    "#C11574",
    "#175CD3",
  ];
  const randomIndex = Math.floor(Math.random() * textColor.length);
  const item = textColor[randomIndex];
  return item;
};

export const formatDate = (dateString: string, toolTip: boolean): string => {
  const time: string = new Date(dateString).toLocaleTimeString();
  const day: number = new Date(dateString).getDate();
  const month: string = MONTH_NAMES[new Date(dateString).getUTCMonth()];
  const years: number = new Date(dateString).getFullYear();
  if (toolTip) {
    return `${day} ${month} ${years}, ${time}`;
  }
  return `${day} ${month} ${years}`;
};

export const convertDateToHoursOrSeconds = (date: Date) => {
  const now = new Date();
  const yesterday = new Date(new Date().setDate(now.getDate() - 1));
  const diffMilliseconds = now.getTime() - date.getTime();
  const diffSeconds = diffMilliseconds / 1000;
  const diffMinutes = diffSeconds / 60;
  const diffHours = diffMinutes / 60;
  const diffDays = diffHours / 24;

  if (diffMinutes < 1) {
    if (diffSeconds === 1) {
      return `1 second ago`;
    }
    return `${Math.floor(diffSeconds)} seconds ago`;
  } else if (diffMinutes < 60) {
    const roundedMinutes = Math.floor(diffMinutes);
    if (roundedMinutes === 1) {
      return `1 minute ago`;
    }
    return `${roundedMinutes} minutes ago`;
  } else if (diffHours < 24) {
    if (Math.floor(diffHours) === 1) {
      return `An hour ago`;
    } else {
      return `${Math.floor(diffHours)} hours ago`;
    }
  } else {
    if (Math.floor(diffDays) === 1 && yesterday.getDate() === date.getDate()) {
      return "Yesterday";
    }
    return formatDate(date.toISOString(), false);
  }
};

export const formatPhoneNumber = (phoneNumber: string): string => {
  return `${COUNTRY_CODE} (${phoneNumber?.substring(0, 3)}) ${phoneNumber?.substring(3, 6)}-${phoneNumber?.substring(6, 10)}`;
};

// To take a string and allows only digits to be returned back. Used in number fields
export const handlePhoneNumber = (phone: string): string => {
  return phone?.replace(/[^\d]/g, "");
};


export const handleImageExtension = (imageType: string): string => {
  if (imageType.includes('jpeg') || imageType.includes('jpg')) {
    return '.jpeg';
  } else {
    return '.png';
  }
};

export const handleSelectedImage = (
  fileSize: number,
  maxSize: number,
  fileType: string,
  allowedFileTypes: string[]
): number | string => {
  if (allowedFileTypes.includes(fileType)) {
    if (fileSize > maxSize) {
      return 'Image size has to be less than 1MB';
    } else {
      return fileSize as number;
    }
  } else {
    return 'Only png and jpeg files are allowed';
  }
};