import React from 'react';
import Skeleton from 'react-loading-skeleton';

const OrganizationDetailsLoader = () => {
  return (
    <>
      <Skeleton className="rounded-xl" height={146} count={1} />

      <Skeleton className="mt-4 rounded-lg" height={200} count={1} />

      <Skeleton height={100} count={1} className="mt-4 rounded-lg" />

      <Skeleton height={200} count={1} className="mt-4 rounded-lg" />
    </>
  );
};

export default OrganizationDetailsLoader;
