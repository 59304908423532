import { useEffect } from 'react';
import { useStore } from '@/hooks/useRedux';
import { useParams } from 'react-router';

import organizationSlice from '../../store/slice/organization.slice';

import OrganizationProfile from '../Components/OrganizationProfile';
import OrganizationHistory from '../Components/OrganizationHistory';
import OrganizationPlan from '../Components/OrganizationPlan';
import { useSearchParams } from 'react-router-dom';

const OrganizationDetails = (): JSX.Element => {
  const { dispatch } = useStore();

  const { state } = useStore();

  const { id } = useParams<{
    id: string;
  }>();
  const [searchParams] = useSearchParams()
  const organization_name = searchParams.get("organization_name") || ""; // Default to empty string if not present
  useEffect(() => {
    dispatch(organizationSlice.actions.resetOrganizationHistory());
    dispatch(organizationSlice.actions.getOrgDetails(id!));
    dispatch(organizationSlice.actions.getOrgPlans(id!));
  }, [id]);

  useEffect(() => {
    dispatch(
      organizationSlice.actions.getOrgHistory({
        id: id!,
        page: state.organizations.historyPage,
      })
    );
  }, [state.organizations.historyPage]);

  return (
    <div className="grid grid-cols-3 gap-5 w-full">
      <OrganizationProfile organization_id={id!} />
      <OrganizationHistory organization_id={id!} />
      <OrganizationPlan organization_id={id!} organization_name={organization_name} />
    </div>
  );
};

export default OrganizationDetails;
