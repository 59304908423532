import React from 'react';

export function OrganizationDefaultLogo(
  props: React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      className="cursor-pointer"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5V19"
        stroke="#667085"
        stroke-width="2.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
      <path
        d="M5 12H19"
        stroke="#667085"
        stroke-width="2.5"
        stroke-linecap="square"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default OrganizationDefaultLogo;
