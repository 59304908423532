import React, { useState } from 'react';
import EditIcon from '../../../../assets/images/svg/edit_black_icon.svg';
import AddIcon from '../../../../assets/images/svg/add_icon.svg';
import Modal from 'react-modal';
import EditPrivacy from './EditPrivacy';
import { useAppSelector } from '@/hooks/useRedux';

const PrivacyPolicy: React.FC<{ organization_id: any }> = ({ organization_id }): JSX.Element => {

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  const editPrivacyModel = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const settingsDetails = useAppSelector(
    state => state.settings.organizationDetails
  );

  return (
    <div className="px-6 py-5 flex flex-col shadow-pageShadow bg-white mb-4 rounded-lg ">
      <div className="flex justify-between items-center  pb-2">
        <span className="text-sm font-medium text-greyBlackColor">
          Privacy Policy
        </span>

        <button
          onClick={editPrivacyModel}
          className="flex justify-center items-center  rounded font-semibold bg-white edit-icon-bg"
        >
          {settingsDetails?.organization_settings.pdf_footer_policy && (
            <img src={EditIcon} className="w-[16px]" alt="" />
          )}
        </button>
      </div>
      {settingsDetails?.organization_settings.pdf_footer_policy ? (
        <div className="border-t border-sideNavButtonBg flex pt-2">
          <span className="text-xs font-medium text-blackLightColor pt-1 leading-5">
            {settingsDetails?.organization_settings.pdf_footer_policy}
          </span>
        </div>
      ) : (
        <div
          className="border-t border-sideNavButtonBg  flex justify-center items-center w-full h-[100px] cursor-pointer"
          onClick={editPrivacyModel}
        >
          <img src={AddIcon} />
          <span className="ml-2"> Add Privacy policy</span>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        className="mx-auto rounded-lg shadow-modal"
        style={{
          overlay: {
            display: 'flex',
            position: 'fixed',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.61)',
          },
        }}
      >
        <EditPrivacy handleClose={closeModal} organization_id={organization_id} />
      </Modal>
    </div>
  );
};

export default PrivacyPolicy;
